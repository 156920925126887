import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { HelmetDatoCms } from "gatsby-source-datocms";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";

import { useI18next } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from "gatsby-plugin-image";

const TemplatesPageFilter = ({ data }) => {
  const { language } = useI18next();
  const templatePage = data.templatePage;
  return (
    <Layout>
      {data.templateSeo.nodes.map((current) => (
        <HelmetDatoCms seo={current.seoMetaTags} />
      ))}
      <main className="templates-page-container">
        <div className="templates-page-title">
          <h1>{templatePage.title}</h1>
          <div className="filters">
            <h6>{templatePage.filter}:</h6>
            {data.tags.nodes.map((tag) => (
              <Link
                to={`/templates/filter/${tag.slug}`}
                activeClassName="active"
                className="template-tag"
                key={tag.id}
                partiallyActive={true}
                language={language}
              >
                <span className="btn-text">{tag.name}</span>
              </Link>
            ))}
            <Link
              to={`/templates/`}
              activeClassName="active"
              className="blog-tag-close"
              language={language}
            >
              <span className="btn-text">X</span>
            </Link>
          </div>
        </div>
        <section className="templates-grid">
          {data.templates.nodes.map((template) => (
            <a
              className="template-item"
              key={template.id}
              href={`${templatePage.url}?${template.linkedTemplate.slug}`}
            >
              <GatsbyImage
                image={template.image.gatsbyImageData}
                alt={template.image.alt}
              />
              <h5>{template.linkedTemplate.name}</h5>
              <p>{template.description}</p>
              <span className="btn btn--purple">
                <span className="btn-text">{templatePage.cta}</span>
              </span>
            </a>
          ))}
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  );
};
export default TemplatesPageFilter;

export const query = graphql`
  query TemplateFilter($language: String!, $tag: String!) {
    templatePage: datoCmsTemplatesPage(locale: { eq: $language }) {
      title
      url
      cta
      filter
    }
    tags: allDatoCmsTemplateTag(filter: { locale: { eq: $language } }) {
      nodes {
        slug
        name
        id
      }
    }
    templates: allDatoCmsTemplate(
      filter: {
        locale: { eq: $language }
        tags: { elemMatch: { slug: { eq: $tag } } }
      }
    ) {
      nodes {
        id
        description
        image {
          gatsbyImageData
          url(imgixParams: { auto: "enhance", q: 100 })
          alt
          width
          height
        }
        linkedTemplate {
          name
          slug
        }
      }
    }
    templateSeo: allDatoCmsTemplateTag(
      filter: { locale: { eq: $language }, slug: { eq: $tag } }
    ) {
      nodes {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
